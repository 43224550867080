<template>
    <!--begin::Dashboard-->
    <div :class="fullscreen ? 'displayFullScreen' : 'card p-5 mt-10'" :style="fullscreen ? 'height:100%' : 'position:relative;'">
        <Chat v-if="classe_user.classeInfo.classroom === 1" :sessionId="classe_user.sessionInfo.id" />
        <b-alert variant="info" class="text-center h6" :show="classe_user.classeInfo && classe_user.classeInfo.classroom == 1">
            <b-icon class="mr-2" icon="info-circle-fill"></b-icon>
            Si vous souhaitez quitter cette page, merci d'utiliser le bouton retour. Si vous quittez cette page en fermant l'onglet ou le
            navigateur votre temps de progression ne sera pas sauvegardé.
        </b-alert>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <div class="d-flex align-items-center flex-row justify-content-between mb-5">
                    <b-skeleton type="button" width="12%"></b-skeleton>
                    <b-skeleton type="input" width="40%"></b-skeleton>
                    <span></span>
                </div>
                <div class="mt-5 d-flex flex-column justify-content-around">
                    <div class="mt-5 d-flex flew-row justify-content-between align-items-center">
                        <div class="mt-5" style="width: 70%">
                            <b-skeleton width="85%"></b-skeleton>
                            <b-skeleton type="input" width="85%"></b-skeleton>
                        </div>
                        <div class="mr-10 mt-5" style="width: 30%">
                            <b-skeleton width="95%"></b-skeleton>
                            <b-skeleton type="input" width="95%"></b-skeleton>
                        </div>
                    </div>
                    <div class="mt-10" style="width: 100%">
                        <b-skeleton></b-skeleton>
                        <b-skeleton type="input" class="mt-2"></b-skeleton>
                    </div>
                </div>
                <div class="mt-15 d-flex justify-content-center">
                    <b-skeleton class="mr-3" type="button"></b-skeleton>
                    <b-skeleton type="button"></b-skeleton>
                </div>
            </template>
            <div
                :class="mobile() ? 'flex-wrap ' : ''"
                :style="mobile() ? 'height:15vh' : 'height:7vh'"
                class="d-flex align-items-center flex-row justify-content-between align-items-center mb-5"
            >
                <div :style="mobile() ? 'width:100% ' : ''" class="d-flex flex-row justify-content-between align-items-center">
                    <div class="d-flex">
                        <div @click="back()">
                            <span class="btn btn-outline-danger btn-md">
                                <span class="svg-icon svg-icon-md svg-icon-light-danger">
                                    <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                                </span>
                                {{ $t('CONTROLLERS.BACK') }}
                            </span>
                        </div>
                        <button
                            v-if="!mobile()"
                            v-b-tooltip.hover.right="`${showMenu ? 'Fermer la liste des éléments' : 'Afficher la liste des éléments'}`"
                            :class="showMenu ? 'svg-icon-danger' : 'svg-icon-primary'"
                            class="svg-icon svg-icon-xl svg-icon-primary ml-5"
                            @click="showMenu = !showMenu"
                        >
                            <inline-svg src="/media/svg/icons/Text/Menu.svg" />
                        </button>
                    </div>
                    <div v-if="mobile()">
                        <button
                            v-b-tooltip.hover.top="'Plein écran'"
                            :class="fullscreen ? 'svg-icon-danger' : 'svg-icon-primary'"
                            class="svg-icon svg-icon-xl mr-2"
                            @click="fullscreen = !fullscreen"
                        >
                            <inline-svg src="/media/svg/icons/General/Expand-arrows.svg" />
                        </button>
                    </div>
                </div>
                <div :class="mobile() ? 'mt-2' : ''" :style="mobile() ? 'max-width:100% ' : 'max-width:50%'">
                    <h1 class="d-inline-block" style="max-width: 100%">
                        {{ lesson.title }}
                    </h1>
                </div>
                <div v-if="!mobile()">
                    <button
                        v-b-tooltip.hover.top="'Plein écran'"
                        :class="fullscreen ? 'svg-icon-danger' : 'svg-icon-primary'"
                        class="svg-icon svg-icon-xl mr-2"
                        @click="toggleFullScreen()"
                    >
                        <inline-svg src="/media/svg/icons/General/Expand-arrows.svg" />
                    </button>
                </div>
            </div>
            <div :style="fullscreen ? 'height:92%' : 'min-height:65vh'" class="d-flex flex-row justify-content-between" style="width: 100%">
                <div
                    v-if="show"
                    :style="`${mobile() ? 'position:absolute;top:0;left:0;overflow-y:hidden;z-index:9999;width:75%' : ''}
                        ;${showMenu ? 'width:30%' : 'width:5%'}`"
                    class="pr-5 scrollPanel"
                    style="background-color: white; overflow-y: scroll"
                >
                    <div v-if="mobile()" class="mb-4 pb-4 d-flex justify-content-end" style="width: 100%">
                        <button class="btn btn-pill btn-outline-danger" style="position: left" @click="showMenu = !showMenu">Fermer</button>
                    </div>
                    <div
                        v-for="item in items"
                        :key="item.id"
                        :style="`${showMenu ? '' : 'height:4vh;'}`"
                        class="mt-1 px-1 py-2 d-flex flex-row justify-content-between align-items-center"
                        style="margin-bottom: 10px"
                        @click="
                            (item.order <= showMax && end) || (!lesson.linear && end === false)
                                ? sendChronoWhenYouClickOnItemMenu(item.order)
                                : ''
                        "
                        @mouseout="
                            (e) => {
                                item.order <= showMax || !lesson.linear ? menuToogleBg(e) : '';
                            }
                        "
                        @mouseover="
                            (e) => {
                                item.order <= showMax || !lesson.linear ? menuToogleBg(e) : '';
                            }
                        "
                    >
                        <div
                            v-if="showMenu"
                            :class="[item.order > showMax && lesson.linear ? 'text-muted' : '', end === true ? 'text-muted' : '']"
                            :style="end === true ? 'pointer-events: none;' : ''"
                            class="d-flex flex-row justify-content-between align-items-center"
                            style="width: 70%; overflow: hidden"
                        >
                            <div
                                v-if="showMenu"
                                :class="item.order === itemView + 1 ? 'font-weight-bold' : ''"
                                :style="showMenu ? 'width:30%;text-align:center' : 'width:100%'"
                                class="width-media"
                            >
                                <div>
                                    <span v-if="items.length === item.order && showMenu" class="courseIcon">
                                        <div
                                            :class="[
                                                showMenu === true ? 'svg-icon-3x' : 'svg-icon-md',
                                                item.order === itemView + 1 ? 'svg-icon-warning' : 'svg-icon-grey'
                                            ]"
                                            class="svg-icon"
                                            title="EVALUATION FINALE"
                                        >
                                            <inline-svg src="/media/svg/icons/Clothes/Crown.svg" />
                                        </div>
                                    </span>
                                    <span v-else-if="item.media_id && !item.media.mime_type.includes('pdf')" class="courseIcon">
                                        <img
                                            v-if="item.order === itemView + 1"
                                            :style="showMenu ? '' : 'width:100%'"
                                            alt="(Media)"
                                            src="/media/svg/icons/Course/media.svg"
                                        />
                                        <img
                                            v-if="item.order !== itemView + 1"
                                            :style="showMenu ? '' : 'width:100%'"
                                            alt="(Media)"
                                            src="/media/png/icons/Course/media.png"
                                        />
                                    </span>
                                    <span v-else-if="item.media_id && item.media.mime_type.includes('pdf')" class="courseIcon">
                                        <img
                                            v-if="item.order === itemView + 1"
                                            :style="showMenu ? '' : 'width:100%'"
                                            alt="(Media)"
                                            src="/media/png/icons/Course/pdf_noir.png"
                                        />
                                        <img
                                            v-if="item.order !== itemView + 1"
                                            :style="showMenu ? '' : 'width:100%'"
                                            alt="(Media)"
                                            src="/media/png/icons/Course/pdf_gris.png"
                                        />
                                    </span>
                                    <span v-else-if="item.survey_item_id" class="courseIcon">
                                        <img
                                            v-if="item.order === itemView + 1"
                                            :style="showMenu ? '' : 'width:100%'"
                                            alt="(Question)"
                                            src="/media/svg/icons/Course/Question.svg"
                                        />
                                        <img
                                            v-if="item.order !== itemView + 1"
                                            :style="showMenu ? '' : 'width:100%'"
                                            alt="(QCM)"
                                            src="/media/png/icons/Course/Question.png"
                                        />
                                    </span>
                                    <span v-else-if="item.survey_id" class="courseIcon">
                                        <img
                                            v-if="item.order === itemView + 1"
                                            :style="showMenu ? '' : 'width:100%'"
                                            alt="(QCM)"
                                            src="/media/svg/icons/Course/QCM.svg"
                                        />
                                        <img
                                            v-if="item.order !== itemView + 1"
                                            :style="showMenu ? '' : 'width:100%'"
                                            alt="(QCM)"
                                            src="/media/png/icons/Course/QCM.png"
                                        />
                                    </span>
                                    <span v-else-if="item.interactives_items_id" class="courseIcon">
                                        <img
                                            v-if="item.order === itemView + 1"
                                            :style="showMenu ? '' : 'width:100%'"
                                            alt="(Media)"
                                            src="/media/svg/icons/Course/media.svg"
                                        />
                                        <img
                                            v-if="item.order !== itemView + 1"
                                            :style="showMenu ? '' : 'width:100%'"
                                            alt="(Media)"
                                            src="/media/png/icons/Course/media.png"
                                        />
                                    </span>
                                </div>
                            </div>
                            <div
                                v-if="showMenu && item"
                                :class="item.order === itemView + 1 ? 'font-weight-bold' : ''"
                                class="hide"
                                style="width: 60%"
                            >
                                <span v-if="item.media_id && showMenu">
                                    {{ item.media.name }}
                                </span>
                                <span v-if="item.survey_item_id && showMenu">
                                    {{ item.surveyItems.title_question ? item.surveyItems.title_question : item.surveyItems.question }}
                                </span>
                                <span v-if="item.survey_id && showMenu">
                                    {{ item.survey.title }}
                                </span>
                                <span v-if="item.interactives_items_id && showMenu">
                                    {{ item.interaction_title }}
                                </span>
                            </div>
                        </div>
                        <div v-if="item.order > showMax && lesson.linear && showMenu" class="svg-icon svg-icon-md svg-icon-muted">
                            <inline-svg src="/media/svg/icons/General/Lock.svg" />
                        </div>
                        <div v-else-if="item.survey_id && showMenu">
                            <div v-if="response.survey[item.survey.id].valid && showMenu">
                                <span class="svg-icon svg-icon-md svg-icon-success">
                                    <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
                                </span>
                                <span v-if="showMenu" class="text-success font-italic text-md">
                                    ({{ `${Math.round(response.survey[item.survey.id].result)}%` }})
                                </span>
                            </div>
                            <div
                                v-else-if="!response.survey[item.survey.id].valid && response.survey[item.survey.id].valid != null"
                                class="svg-icon svg-icon-md svg-icon-danger"
                            >
                                <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
                            </div>
                        </div>
                        <div v-else-if="item.survey_item_id && showMenu">
                            <div v-if="response[item.surveyItems.id].valid && showMenu" class="svg-icon svg-icon-md svg-icon-success">
                                <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
                            </div>
                            <div
                                v-else-if="!response[item.surveyItems.id].valid && response[item.surveyItems.id].valid != null && showMenu"
                                class="svg-icon svg-icon-md svg-icon-danger"
                            >
                                <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
                            </div>
                        </div>
                        <div v-else-if="item.media_id && showMenu">
                            <div
                                v-if="response.media[item.media_id].valid === true && showMenu"
                                class="svg-icon svg-icon-md svg-icon-success"
                            >
                                <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="viewer" class="d-flex flex-column justify-content-between" style="width: 100%; min-height: 65vh">
                    <div
                        v-if="fullscreen === false"
                        :style="mobile() ? 'height:10%' : 'height:5%'"
                        class="d-flex flex-column justify-content-between"
                        style="width: 100%"
                    >
                        <div class="d-flex flex-row align-items-center justify-content-center" style="width: 100%">
                            <button
                                v-b-tooltip.hover.top="'Précédent'"
                                :style="progress_bar.value <= 1 ? 'display:none' : 'display:block'"
                                class="svg-icon svg-icon-xl svg-icon-primary mr-2"
                                @click="previous()"
                            >
                                <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
                            </button>
                            <div style="width: 90%">
                                <b-progress :max="progress_bar.max" height="2vh" style="width: 100%" width="100%">
                                    <b-progress-bar :value="end ? progress_bar.max : progress_bar.value">
                                        <span v-if="!end && progress_bar.value < progress_bar.max">
                                            {{ progress_bar.value }} / {{ progress_bar.max }}
                                        </span>
                                        <span v-else-if="progress_bar.value === progress_bar.max || end">Terminé</span>
                                    </b-progress-bar>
                                </b-progress>
                            </div>
                            <button
                                :class="end ? 'svg-icon-warning' : 'svg-icon-grey'"
                                class="svg-icon svg-icon-xl ml-2"
                                title="EVALUATION FINALE"
                            >
                                <inline-svg src="/media/svg/icons/Clothes/Crown.svg" />
                            </button>
                        </div>
                    </div>
                    <div
                        id="item"
                        :class="items[itemView] && !items[itemView].media_id ? 'scrollPanel' : ''"
                        :style="items[itemView] && !items[itemView].media_id ? 'overflow-y: auto;min-height: 65% ' : 'max-height: 85%'"
                        class="d-flex flex-row justify-content-around"
                        style="width: auto; height: 100%"
                    >
                        <div v-if="showAlert" class="alertDuration d-flex flex-column align-items-center">
                            <h4>Êtes vous toujours là ?</h4>
                            <p class="text-center">
                                Afin de nous assurer que vous êtes toujours en<br />train de suivre le cours, veuillez cliquer sur le bouton
                                ci-dessous.
                            </p>
                            <b-button
                                class="px-5"
                                pill
                                variant="primary"
                                @click="
                                    sendChrono();
                                    showAlert = false;
                                    randomAlert();
                                "
                                >Ok
                            </b-button>
                        </div>
                        <!-- modal pour vérifier que l'utilisateur est encore là? -->

                        <div
                            class="rounded"
                            :class="[items[itemView] && items[itemView].media_id ? 'd-flex' : '', mobile() ? '' : 'p-3']"
                            style="width: 90%; max-height: 100%"
                        >
                            <!-- Media  -->
                            <div
                                v-if="items[itemView] && items[itemView].media_id"
                                class="d-flex flex-column justify-content-around align-items-center"
                                style="width: 90%; height: 100%; margin: 0 auto"
                            >
                                <div
                                    v-if="items[itemView].media.description && !items[itemView].media.mime_type.includes('zip')"
                                    style="max-width: 100%"
                                >
                                    <h2>{{ items[itemView].media.description }}</h2>
                                </div>
                                <div class="d-flex justify-content-around" style="width: 100%; height: 100%">
                                    <video
                                        v-if="items[itemView].media.mime_type.includes('video')"
                                        :key="items[itemView].media.url"
                                        controls
                                        controlsList="nodownload noremoteplayback"
                                        disablePictureInPicture
                                        oncontextmenu="return false;"
                                        style="max-height: 100%"
                                        width="100%"
                                        @fullscreenchange="handleFullscreenChange"
                                    >
                                        <source :src="items[itemView].media.url" />
                                    </video>
                                    <img
                                        v-if="items[itemView].media.mime_type.includes('image')"
                                        :alt="items[itemView].media.name"
                                        :src="items[itemView].media.url"
                                        style="max-width: 100%; max-height: 60vh !important"
                                    />
                                    <div v-if="items[itemView].media.mime_type.includes('zip')" style="width: 100%; height: 100%">
                                        <iframe
                                            :src="items[itemView].media.url.split('.').slice(0, -1).join('.') + '/index.html'"
                                            frameborder="0"
                                            style="width: 100%; height: 100%; resize: both; margin-top: 2rem"
                                        >
                                        </iframe>
                                    </div>
                                    <div
                                        style="min-height: 45rem; width: 100%; height: 100%"
                                        v-if="items[itemView].media.mime_type.includes('pdf')"
                                    >
                                        <embed
                                            :src="items[itemView].media.url + '#toolbar=0&navpanes=0&scrollbar=0'"
                                            style="width: 100%; height: 100%"
                                            type="application/pdf"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="items[itemView].interactive_id"
                                class="d-flex flex-column justify-content-around align-items-center"
                                style="width: 90%; height: 100%; margin: 0 auto"
                            >
                                <div style="width: 100%; height: 100%">
                                    <iframe
                                        :src="
                                            items[itemView].interactives_item.media_url.split('.').slice(0, -1).join('.') +
                                            '/index.html?question=' +
                                            items[itemView].interactive_id +
                                            '&mode=0'
                                        "
                                        frameborder="0"
                                        style="width: 100%; height: 100%; resize: both"
                                    >
                                    </iframe>
                                </div>
                            </div>
                            <!-- Question  -->
                            <div
                                v-if="items[itemView] && items[itemView].survey_item_id"
                                class="d-flex flex-column justify-content-around align-items-center"
                                style="height: 100%"
                            >
                                <div
                                    v-if="response[items[itemView].surveyItems.id].valid"
                                    class="bg-success rounded d-flex flex-row align-items-center justify-content-around"
                                    style="width: 100%; height: 7vh"
                                >
                                    <span class="text-white"> Félicitations ! Vous avez répondu juste ! </span>
                                    <button v-if="showValid" class="btn btn-pill btn-outline-white" @click="finish()">Terminer</button>
                                </div>
                                <div
                                    v-if="!response[items[itemView].surveyItems.id].valid && !showQuestion"
                                    class="bg-danger rounded d-flex flex-row align-items-center justify-content-around"
                                    style="width: 100%; min-height: 5vh"
                                >
                                    <div class="d-flex flex-column my-5" style="max-width: 75%">
                                        <span class="text-white"> Vous n'avez pas répondu juste à cette question. </span>
                                        <span
                                            :class="items[itemView].surveyItems.message ? 'border-bottom pb-5 my-2' : 'mt-2'"
                                            class="text-white border-white"
                                        >
                                            Vous pouvez le recommencer immédiatement, cependant, nous vous invitons à revoir les pages
                                            précédentes.
                                        </span>
                                        <span v-if="items[itemView].surveyItems.message" class="text-white mt-5">
                                            Indication du concepteur :
                                            {{ items[itemView].surveyItems.message }}
                                        </span>
                                    </div>
                                </div>
                                <div v-if="showQuestion">
                                    <h2>{{ items[itemView].surveyItems.question }}</h2>
                                </div>
                                <div
                                    v-if="showQuestion"
                                    class="d-flex flex-column justify-content-around"
                                    style="min-height: 60%; width: 100%"
                                >
                                    <div class="d-flex flex-row justify-content-around align-items-center">
                                        <b-form-checkbox
                                            id="answer_a"
                                            v-model="response[items[itemView].surveyItems.id].anwser_a"
                                            :disabled="response[items[itemView].surveyItems.id].valid === true"
                                            size="lg"
                                        />
                                        <label class="h3 m-0" for="answer_a" style="width: 80%">
                                            {{ items[itemView].surveyItems.answer_a }}
                                        </label>
                                    </div>
                                    <div class="d-flex flex-row justify-content-around align-items-center">
                                        <b-form-checkbox
                                            id="answer_b"
                                            v-model="response[items[itemView].surveyItems.id].anwser_b"
                                            :disabled="response[items[itemView].surveyItems.id].valid === true"
                                            size="lg"
                                        />
                                        <label class="h3 m-0" for="answer_b" style="width: 80%">
                                            {{ items[itemView].surveyItems.answer_b }}
                                        </label>
                                    </div>
                                    <div
                                        v-if="items[itemView].surveyItems.answer_c && items[itemView].surveyItems.answer_c.trim() !== ''"
                                        class="d-flex flex-row justify-content-around align-items-center"
                                    >
                                        <b-form-checkbox
                                            id="answer_c"
                                            v-model="response[items[itemView].surveyItems.id].anwser_c"
                                            :disabled="response[items[itemView].surveyItems.id].valid === true"
                                            size="lg"
                                        />
                                        <label for="answer_c" class="h3 m-0" style="width: 80%">
                                            {{ items[itemView].surveyItems.answer_c }}
                                        </label>
                                    </div>
                                    <div
                                        v-if="items[itemView].surveyItems.answer_d && items[itemView].surveyItems.answer_d.trim() !== ''"
                                        class="d-flex flex-row justify-content-around align-items-center"
                                    >
                                        <b-form-checkbox
                                            id="anwser_d"
                                            v-model="response[items[itemView].surveyItems.id].anwser_d"
                                            :disabled="response[items[itemView].surveyItems.id].valid === true"
                                            size="lg"
                                        />
                                        <label class="h3 m-0" for="anwser_d" style="width: 80%">
                                            {{ items[itemView].surveyItems.answer_d }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!-- QCM  -->
                            <div
                                v-if="items[itemView] && items[itemView].survey_id"
                                class="d-flex flex-column justify-content-around align-items-center"
                            >
                                <div
                                    v-if="response.survey[items[itemView].survey_id].valid"
                                    class="bg-success rounded d-flex flex-row align-items-center justify-content-around"
                                    style="width: 100%; height: 7vh"
                                >
                                    <span class="text-white">
                                        Félicitations ! Vous avez réussi ce questionnaire avec un score de
                                        {{ Math.round(response.survey[items[itemView].survey_id].result) }}%
                                    </span>
                                </div>

                                <div
                                    v-else-if="!response.survey[items[itemView].survey_id].valid && !showQuestion"
                                    class="bg-danger mt-5 rounded d-flex flex-row align-items-center justify-content-around"
                                    style="width: 100%; min-height: 5vh"
                                >
                                    <div class="d-flex flex-column my-5" style="max-width: 75%">
                                        <span class="text-white"> Vous n'avez pas réussi ce test. </span>
                                        <span
                                            :class="items[itemView].survey.message ? 'border-bottom pb-2 my-2' : 'mt-2'"
                                            class="text-white border-white pb-5"
                                        >
                                            Vous pouvez le recommencer immédiatement, cependant, nous vous invitons à revoir les pages
                                            précédentes.
                                        </span>
                                        <span v-if="items[itemView].survey.message" class="text-white mt-5">
                                            Indication du concepteur :
                                            {{ items[itemView].survey.message }}
                                        </span>
                                    </div>
                                </div>
                                <!-- message d'erreur lorsque le te ps n'est pas respecter -->
                                <div
                                    v-if="showBlocked && min_duration"
                                    class="bg-danger rounded d-flex flex-column align-items-center justify-content-around p-5"
                                    style="width: 100%; min-height: 5vh"
                                >
                                    <p class="text-white">
                                        La durée minimum sur cette leçon est de
                                        {{ min_duration }} minutes
                                    </p>
                                    <p class="text-white">Vous avez passé : {{ duration }}</p>
                                    <p class="text-white">Vous devez revoir les pages précédents.</p>
                                </div>
                                <!-- message d'erreur lorsque le te ps n'est pas respecter -->
                                <div v-else-if="showQuestion" style="width: 90%">
                                    <div
                                        v-for="(question, index) in items[itemView].surveyItems"
                                        :key="question.id"
                                        :class="question.solution != question.answered && showWrongAnswers ? 'wrong-answer-border' : ''"
                                        class="my-5 pb-5 border-bottom border-secondary"
                                    >
                                        <div class="mb-10">
                                            <h2>
                                                <i class="text-muted mr-5">{{ index + 1 + '/' + items[itemView].surveyItems.length }}</i
                                                >{{ question.question }}
                                            </h2>
                                        </div>
                                        <div class="d-flex flex-column justify-content-around" style="height: 40%; width: 100%">
                                            <div class="d-flex flex-row justify-content-around align-items-center my-2">
                                                <b-form-checkbox
                                                    :id="question.id + 'a'"
                                                    v-model="response[question.id].anwser_a"
                                                    :disabled="response.survey[items[itemView].survey_id].result == 100 || showWrongAnswers"
                                                    size="md"
                                                />
                                                <label
                                                    :class="getClass(question, 'a')"
                                                    :for="question.id + 'a'"
                                                    class="h3 m-0"
                                                    style="width: 80%"
                                                >
                                                    {{ question.answer_a }}
                                                </label>
                                            </div>
                                            <div class="d-flex flex-row justify-content-around align-items-center my-2">
                                                <b-form-checkbox
                                                    :id="question.id + 'b'"
                                                    v-model="response[question.id].anwser_b"
                                                    :disabled="response.survey[items[itemView].survey_id].result == 100 || showWrongAnswers"
                                                    size="md"
                                                />
                                                <label
                                                    :class="getClass(question, 'b')"
                                                    :for="question.id + 'b'"
                                                    class="h3 m-0"
                                                    style="width: 80%"
                                                >
                                                    {{ question.answer_b }}
                                                </label>
                                            </div>
                                            <div
                                                v-if="question.answer_c != ' ' && question.answer_c != '' && question.answer_c"
                                                class="d-flex flex-row justify-content-around align-items-center my-2"
                                            >
                                                <b-form-checkbox
                                                    :id="question.id + 'c'"
                                                    v-model="response[question.id].anwser_c"
                                                    :disabled="response.survey[items[itemView].survey_id].result == 100 || showWrongAnswers"
                                                    size="md"
                                                />
                                                <label
                                                    :class="getClass(question, 'c')"
                                                    :for="question.id + 'c'"
                                                    class="h3 m-0"
                                                    style="width: 80%"
                                                >
                                                    {{ question.answer_c }}
                                                </label>
                                            </div>
                                            <div
                                                v-if="question.answer_d != ' ' && question.answer_d != '' && question.answer_d"
                                                class="d-flex flex-row justify-content-around align-items-center my-2"
                                            >
                                                <b-form-checkbox
                                                    :id="question.id + 'd'"
                                                    v-model="response[question.id].anwser_d"
                                                    :disabled="response.survey[items[itemView].survey_id].result == 100 || showWrongAnswers"
                                                    size="md"
                                                />
                                                <label
                                                    :class="getClass(question, 'd')"
                                                    :for="question.id + 'd'"
                                                    class="h3 m-0"
                                                    style="width: 80%"
                                                >
                                                    {{ question.answer_d }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- footer des formulaires avec les boutons -->
                    <div
                        :style="mobile() ? 'height: 100%' : ''"
                        class="d-flex flex-row justify-content-center align-items-center"
                        style="width: 100%; height: 10%; margin-bottom: 50px"
                    >
                        <button v-if="showPrevious" class="btn btn-md btn-pill btn-outline-primary mr-2" @click="previous()">
                            <span class="svg-icon svg-icon-md svg-icon-light-danger">
                                <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                            </span>
                            Précédent
                        </button>
                        <!--////////////////////////////////////////-->
                        <button v-if="showValid" class="btn btn-md btn-pill btn-primary mr-2" @click="valid()">
                            Valider
                            {{ items[itemView] && items[itemView].survey_item_id ? 'la question' : 'mes réponses' }}
                        </button>
                        <!-- <button
                            v-else-if="
                                items[itemView] &&
                                    items[itemView].survey_id &&
                                    response.survey[items[itemView].survey_id].valid &&
                                    response.survey[this.items[this.itemView].survey_id].result < 100 &&
                                    showQuestion
                            "
                            class="btn btn-lg btn-pill btn-primary mx-5 px-4 py-2"
                            @click="valid()"
                        >
                            Améliorer mon score
                        </button> -->
                        <button
                            v-if="
                                (items[itemView] &&
                                    items[itemView].survey_id &&
                                    !response.survey[items[itemView].survey_id].valid &&
                                    !showQuestion) ||
                                (items[itemView] &&
                                    items[itemView].survey_item_id &&
                                    !response[items[itemView].surveyItems.id].valid &&
                                    !showQuestion)
                            "
                            class="btn btn-lg btn-pill btn-primary mx-5 px-4 py-2"
                            @click="resetFail(), check()"
                        >
                            Réessayer maintenant
                        </button>
                        <button v-if="showNext" class="btn btn-md btn-pill btn-outline-primary ml-2" @click="next()">
                            Suivant
                            <span class="svg-icon svg-icon-md svg-icon-light-danger">
                                <inline-svg src="/media/svg/icons/Navigation/Arrow-right.svg"></inline-svg>
                            </span>
                        </button>
                        <button v-if="showFinish" class="btn btn-pill btn-outline-success mx-2 font-height-bolder" @click="finish()">
                            Terminer
                        </button>
                    </div>
                    <!-- footer des formulaires avec les boutons -->
                </div>
            </div>
        </b-skeleton-wrapper>
        <div
            v-if="submit"
            class="d-flex flex-column justify-content-around align-items-center"
            style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 100;
                background-color: #ffffff9e;
                backdrop-filter: blur(5px);
            "
        >
            <div class="d-flex flex-column justify-content-around align-items-center" style="z-index: 200">
                <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
                <p class="text-primary py-2 my-2">Enregistrement en cours...</p>
            </div>
        </div>
        <div
            v-if="!this.classe_user.classeInfo.on || classError"
            class="d-flex flex-column justify-content-around align-items-center"
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 100; background-color: #ffff"
        >
            <div class="d-flex flex-column justify-content-around align-items-center h-50" style="z-index: 200">
                <div class="text-center">
                    <h4 class="text-primary py-2 my-2">Nous sommes désolés, vous ne pouvez plus suivre cette formation pour le moment.</h4>
                    <h4 class="text-primary py-2 my-2">
                        Elle est temporairement indisponible car son contenu fait l'objet d'une mise à jour.
                    </h4>
                </div>
                <router-link :to="{ name: 'classe', params: { id: this.classe_user.id } }" replace>
                    <span class="btn btn-primary btn-xl"> Retourner au tableau de bord </span>
                </router-link>
                <p class="text-muted py-2 my-2">Vous allez être redirigé automatiquement vers le tableau de bord.</p>
            </div>
        </div>
    </div>
    <!--end::Dashboard-->
</template>

<script>
import { GET_CLASSEUSER } from '@/core/services/store/api/classe_users.service';
import { mapGetters } from 'vuex';
import ApiService from '@/core/services/api.service';

export default {
    name: 'play-lesson',
    components: {
        Chat: () => import('@/view/content/components/Chat.vue')
    },
    async beforeMount() {
        this.startLoading();
        if (this.$route.params.id && this.$route.params.classeUserId) {
            await this.$store
                .dispatch(GET_CLASSEUSER, {
                    classeUser_id: this.$route.params.classeUserId
                })
                .then((result) => {
                    if (result.data.user_id !== this.$store.getters.currentUser.id || !result.data.classeInfo.on) {
                        this.$router.push({ name: 'dashboard' });
                    } else {
                        ApiService.put(process.env.VUE_APP_API_URL + '/academy/classeUsers/state', {
                            classe_user_id: result.data.id,
                            lesson_id: this.$route.params.id
                        });
                        this.initItemsResult();
                        this.randomAlert();
                    }
                })
                .catch(() => {
                    this.classError = true;
                });
        } else {
            await this.$router.push({ name: 'dashboard' });
        }
    },
    mounted() {
        const itemMenu = document.querySelectorAll('.item-menu');

        if (this.$route.name == 'lesson' && itemMenu.length > 0) {
            const clickHandler = (e) => {
                this.sendChrono();
                this.clearLoadingTimeInterval();
                itemMenu.forEach((element) => {
                    if (element !== e.target && !element.contains(e.target) && !e.target.contains(element)) {
                        element.removeEventListener('click', clickHandler);
                    }
                });
            };

            itemMenu.forEach((element) => {
                element.addEventListener('click', clickHandler, { once: true });
            });
        }

        setInterval(() => {
            this.chrono++;
        }, 1000);
        // this.randomAlert();
    },
    computed: {
        ...mapGetters({ classe_user: 'getClasseUser' }),
        getAllSuccessItem() {
            return this.items
                .filter((item) => {
                    return item.userResult;
                })
                .filter((item) => {
                    return item.userResult.sucess;
                }).length;
        }
    },
    data() {
        return {
            items: [],
            lesson: [],
            loading: false,
            loadingTime: 0,
            fullscreen: false,
            maxLoadingTime: 1,
            show: true,
            showMenu: true,
            showMax: 1,
            hideStyles: false,
            showQuestion: true,
            showWrongAnswers: false,
            end: false,
            submit: false,
            title: null,
            itemView: 0,
            response: {},
            progress_bar: {
                value: 0,
                max: 0
            },
            classError: false,
            chrono: 0,
            showAlert: false,
            isVideoFullscreen: false,
            min_duration: null,
            duration: null,
            showNext: false,
            showPrevious: false,
            showValid: false,
            showFinish: false,
            showBlocked: false
        };
    },
    methods: {
        check() {
            // Initialiser des indicateurs pour contrôler la visibilité des éléments de l'interface utilisateur
            this.showNext = false;
            this.showPrevious = false;
            this.showValid = false;
            this.showFinish = false;
            this.showBlocked = false;

            // Extraire des informations sur l'élément de sondage actuel et sa réponse
            const questionSeul = this.items[this.itemView]?.survey_item_id;
            const questionSeulResponse = this.response[questionSeul]?.valid;

            // Extraire des informations sur le questionnaire actuel et sa réponse
            const questionnaire = this.items[this.itemView]?.survey;
            const questionnaireResponse = this.response.survey[this.items[this.itemView]?.survey_id]?.valid;

            // Définir la visibilité du bouton 'Précédent' en fonction de la vue actuelle de l'élément
            this.showPrevious = this.itemView !== 0;

            // Définir la visibilité du bouton 'Suivant' en fonction de conditions liées à l'élément de sondage et au questionnaire
            if (this.itemView !== this.items.length - 1) {
                this.showNext = !(questionSeul || questionnaire);
            }

            // Définir la visibilité du bouton 'Valider' en fonction de la présence d'un élément de sondage ou d'un questionnaire
            this.showValid = questionSeul || questionnaire;

            // Gérer les conditions de visibilité pour les questionnaires avec un type spécifié et une vérification de la durée
            if (questionnaire && this.items[this.itemView]?.survey.type) {
                if (this.checkDuration()) {
                    // Si la réponse au questionnaire est valide, afficher le bouton 'Terminer' et masquer 'Précédent' et 'Valider'
                    if (questionnaireResponse) {
                        this.showPrevious = false;
                        this.showFinish = true;
                        this.showValid = false;
                    } else if (!questionnaireResponse && !this.showQuestion) {
                        // S'il n'y a pas de réponse et que l'on ne montre pas de question, masquer le bouton 'Valider'
                        this.showValid = false;
                    } else {
                        // Sinon, afficher le bouton 'Valider'
                        this.showValid = true;
                    }
                } else {
                    // Si la vérification de la durée échoue, afficher 'Bloqué' et masquer 'Valider'
                    this.showBlocked = true;
                    this.showValid = false;
                }
            } else if (questionnaire) {
                // Gérer les conditions de visibilité pour les questionnaires sans type spécifié
                if (questionnaireResponse) {
                    // Si la réponse au questionnaire est valide, afficher le bouton 'Suivant' et masquer 'Valider'
                    this.showNext = true;
                    this.showValid = false;
                } else if (!questionnaireResponse && !this.showQuestion) {
                    // S'il n'y a pas de réponse et que l'on ne montre pas de question, masquer le bouton 'Valider'
                    this.showValid = false;
                } else {
                    // Sinon, afficher le bouton 'Valider'
                    this.showValid = true;
                }
            }

            // Gérer les conditions de visibilité pour les éléments de sondage
            if (questionSeul) {
                if (questionSeulResponse) {
                    // Si la réponse à l'élément de sondage est valide, afficher le bouton 'Suivant' et masquer 'Valider'
                    this.showNext = true;
                    this.showValid = false;
                } else if (!questionSeulResponse && !this.showQuestion) {
                    // S'il n'y a pas de réponse et que l'on ne montre pas de question, masquer le bouton 'Valider'
                    this.showValid = false;
                } else {
                    // Sinon, afficher le bouton 'Valider'
                    this.showValid = true;
                }
            }
        },

        async sendChronoWhenYouClickOnItemMenu(itemOrder) {
            await this.sendChrono(); // Attend que sendChrono soit terminé
            await this.$store
                .dispatch(GET_CLASSEUSER, {
                    classeUser_id: this.$route.params.classeUserId
                })
                .then((result) => {
                    this.lesson = result.data.lessons.filter((lessons) => {
                        return parseInt(lessons.id) === parseInt(this.$route.params.id);
                    })[0];
                    this.checkDuration();
                    this.goTo(itemOrder - 1);
                });
        },
        handleFullscreenChange() {
            this.isVideoFullscreen = document.fullscreenElement !== null;
        },
        randomAlert() {
            if (this.classe_user.classeInfo.classroom) {
                let random = this.getRandomNumber();
                setTimeout(() => {
                    if (this.isVideoFullscreen) {
                        var video = document.querySelector('video');
                        video.pause();
                        if (document.exitFullscreen) {
                            document.exitFullscreen();
                        } else if (document.mozCancelFullScreen) {
                            document.mozCancelFullScreen();
                        } else if (document.webkitExitFullscreen) {
                            document.webkitExitFullscreen();
                        } else if (document.msExitFullscreen) {
                            document.msExitFullscreen();
                        }
                    }
                    this.showAlert = true;
                }, random * 1000);
            }
        },
        getRandomNumber() {
            return (
                Math.floor(Math.random() * (parseInt(process.env.VUE_APP_ALERT_MAX) - parseInt(process.env.VUE_APP_ALERT_MIN) + 1)) +
                parseInt(process.env.VUE_APP_ALERT_MIN)
            );
        },
        back() {
            this.sendChrono();
            this.$router.push({
                name: 'classe',
                params: { id: this.classe_user.id }
            });
        },
        sendChrono() {
            let data = {
                classeUser: this.classe_user.id,
                lessonItem: this.items[this.itemView].id,
                duration: this.chrono
            };
            //when api as finished, reset chrono
            ApiService.post(process.env.VUE_APP_API_URL + '/academy/lessonItemState/duration', data)
                .then(() => {
                    this.chrono = 0;
                })
                .catch(() => {
                    this.chrono = 0;
                });
        },
        toggleFullScreen() {
            this.fullscreen = !this.fullscreen;
            if (this.fullscreen === true) {
                this.showMenu = false;
            } else {
                this.showMenu = true;
            }
        },
        checkDuration() {
            if (this.lesson.min_duration) {
                this.min_duration = this.lesson.min_duration;
            }
            if (this.lesson.duration) {
                //convert seconds to minutes and seconds
                let minutes = Math.floor(this.lesson.duration / 60);
                let seconds = this.lesson.duration - minutes * 60;
                this.duration = minutes + ' minutes et ' + seconds + ' secondes';
            }
            return this.lesson.min_duration * 60 <= this.lesson.duration;
        },
        // BEGIN : INIT LIST
        initItemsResult() {
            this.lesson = this.classe_user.lessons.filter((lessons) => {
                return parseInt(lessons.id) === parseInt(this.$route.params.id);
            })[0];
            let test = []; // QUESTION UNIQUE AND SURVEY'S QUESTIONS [repA,repB,repC,repD,state(if UNIQUE QUESTION)]
            let survey = []; // SURVEY STATE [score,state]
            let media = []; // MEDIA STATE [state]
            this.items = this.lesson.items;
            this.items.forEach((element) => {
                if (element.survey_id) {
                    element.surveyItems.forEach((question) => {
                        test[question.id] = {
                            anwser_a: false,
                            anwser_b: false,
                            anwser_c: false,
                            anwser_d: false
                        };
                    });
                    if (element.userResult) {
                        survey[element.survey_id] = {
                            valid: element.userResult.sucess,
                            result: element.userResult.score
                        };
                        if (element.userResult.sucess) {
                            this.end = true;
                        }
                    } else {
                        survey[element.survey_id] = {
                            valid: null,
                            result: null
                        };
                    }
                } else if (element.survey_item_id) {
                    if (element.userResult) {
                        test[element.surveyItems.id] = {
                            anwser_a: !!element.surveyItems.solution.includes('A'),
                            anwser_b: !!element.surveyItems.solution.includes('B'),
                            anwser_c: !!element.surveyItems.solution.includes('C'),
                            anwser_d: !!element.surveyItems.solution.includes('D'),
                            valid: !!element.userResult.sucess
                        };
                    } else {
                        test[element.surveyItems.id] = {
                            anwser_a: false,
                            anwser_b: false,
                            anwser_c: false,
                            anwser_d: false,
                            valid: null
                        };
                    }
                } else {
                    if (element.userResult) {
                        media[element.media_id] = {
                            valid: !!element.userResult.sucess
                        };
                    } else {
                        media[element.media_id] = {
                            valid: null
                        };
                    }
                }
            });
            // RESPONSE[TEST[...,SURVEY[...,...],...],MEDIA[...]]
            test.survey = survey;
            this.response = test;
            this.response.media = media;
            this.progress_bar.max = this.items.length;
            this.progress_bar.value = this.getAllSuccessItem;
            this.showMax = this.progress_bar.value + 1;
            if (this.lesson.linear) {
                this.goTo(this.progress_bar.value);
            } else if (this.$route.params.item) {
                this.goTo(this.$route.params.item - 1);
            } else {
                document.addEventListener('keydown', (e) => {
                    if (e.keyCode === 37) {
                        this.goTo(this.itemView - 1);
                    } else if (e.keyCode === 39) {
                        this.goTo(this.itemView + 1);
                    }
                });
                let sortable;
                if (this.progress_bar.value > 0) {
                    sortable = [];
                    for (let id in this.items) {
                        if (this.items[id]['userResult']) {
                            sortable.push([
                                id,
                                this.items[id]['userResult']['updated_at']
                                    ? this.items[id]['userResult']['updated_at']
                                    : this.items[id]['userResult']['created_at']
                            ]);
                        }
                    }
                    sortable.sort(function (a, b) {
                        return new Date(a[1]) - new Date(b[1]);
                    });
                } else {
                    sortable = false;
                }
                this.goTo(sortable === false ? 0 : parseInt(sortable.slice(-1)[0][0]));
                //this.goTo( 0);
            }
        },
        // END : INIT LIST
        //////////////////////////
        //BEGIN : SKELETON LOADING
        clearLoadingTimeInterval() {
            clearInterval(this.$_loadingTimeInterval);
            this.$_loadingTimeInterval = null;
        },
        startLoading() {
            this.loading = true;
            this.loadingTime = 0;
        },
        //END : SKELETON LOADING
        //////////////////////
        //BEGIN Navigation
        async next() {
            if (!this.items[this.itemView]['userResult']) {
                await this.valid();
            }
            await this.sendChrono();
            //update duration
            await this.$store
                .dispatch(GET_CLASSEUSER, {
                    classeUser_id: this.$route.params.classeUserId
                })
                .then((result) => {
                    this.lesson = result.data.lessons.filter((lessons) => {
                        return parseInt(lessons.id) === parseInt(this.$route.params.id);
                    })[0];
                    if (this.items[this.itemView + 1] !== undefined) {
                        this.progress_bar.value = this.getAllSuccessItem;
                        if (!this.items[this.itemView + 1]['userResult']) {
                            this.progress_bar.value++;
                            this.resetFail();
                        } else {
                            if (!this.items[this.itemView + 1]['userResult']['sucess']) {
                                this.progress_bar.value++;
                                this.resetFail();
                            } else if (this.items[this.itemView + 1].survey_item_id || this.items[this.itemView + 1].survey_id) {
                                this.showQuestion = false;
                            }
                        }
                        this.itemView++;
                        this.showMax++;
                    }
                    this.check();
                });
        },
        async previous() {
            if (!this.items[this.itemView]['userResult']) {
                await this.valid();
            }
            this.sendChrono();
            this.$nextTick(() => {
                if (this.itemView - 1 >= 0) {
                    this.progress_bar.value = this.getAllSuccessItem;
                    if (!this.items[this.itemView - 1]['userResult']) {
                        this.progress_bar.value++;
                        this.resetFail();
                    } else {
                        if (this.items[this.itemView]['userResult']) {
                            if (!this.items[this.itemView]['userResult']['sucess']) {
                                this.resetFail();
                                this.showQuestion = false;
                            } else if (this.items[this.itemView - 1].survey_item_id || this.items[this.itemView - 1].survey_id) {
                                this.showQuestion = false;
                            }
                        }
                    }
                    this.itemView--;
                }
                this.check();
            });
        },
        goTo(elId) {
            /*
              if(this.items[elId].userResult){
                  this.valid();
              }
            */
            if (this.items[elId].id !== undefined) {
                this.progress_bar.value = this.getAllSuccessItem;
                if (!this.items[elId]['userResult']) {
                    this.progress_bar.value++;
                    this.resetFail();
                } else {
                    if (!this.items[elId]['userResult']['sucess']) {
                        this.progress_bar.value++;
                        this.resetFail();
                    } else if (this.items[elId].survey_item_id || this.items[elId].survey_id) {
                        this.showQuestion = false;
                    }
                }
                this.itemView = elId;
                this.check();
                //this.show = false;
            }
        },
        //END Navigation
        //////////////////////
        //BEGIN FORM QUESTION && QUESTIONNAIRE
        async valid() {
            await this.reloadClasseUser();
            if (this.classe_user.classeInfo.on) {
                /*
               REQUETE ENREGISTREMENT RESULT
               => POST : plateforme.../api/academy/lessonitemState
              PARAMS :
                - CLASSE_USER_iD : this.classe_user_id
                - LESSON_ITEM_ID: this.items[this.itemView].id
                - TYPE: 'media' SI this.items[this.itemView].media_id !== null
                        || 'question' SI this.items[this.itemView].survey_item_id !== null
                        || 'qcm' SI this.items[this.itemView].survey_id !== null
               - RESULTS: NULL SI this.items[this.itemView].media_id !== null
                          || STRING SI this.items[this.itemView].survey_id !== null
                          || ARRAY [SURVEY_ITEM_ID[N] = RESULT , ... ] SI this.items[this.itemView].media_id !== null
               */
                let itemResult = {
                    classe_user_id: this.classe_user.id,
                    lesson_item_id: this.items[this.itemView].id,
                    type: null,
                    results: null
                };
                if (this.items[this.itemView].survey_id) {
                    itemResult.type = 'qcm';
                    itemResult.results = [];
                    this.items[this.itemView].surveyItems.forEach((question) => {
                        let response = '';
                        if (this.response[question.id].anwser_a) {
                            response += 'A';
                        }
                        if (this.response[question.id].anwser_b) {
                            response += 'B';
                        }
                        if (this.response[question.id].anwser_c) {
                            response += 'C';
                        }
                        if (this.response[question.id].anwser_d) {
                            response += 'D';
                        }
                        itemResult.results.push({
                            survey_item_id: question.id,
                            response: response
                        });
                        question.answered = response;
                    });
                } else if (this.items[this.itemView].survey_item_id) {
                    itemResult.type = 'question';
                    let response = '';
                    if (this.response[this.items[this.itemView].surveyItems.id].anwser_a) {
                        response += 'A';
                    }
                    if (this.response[this.items[this.itemView].surveyItems.id].anwser_b) {
                        response += 'B';
                    }
                    if (this.response[this.items[this.itemView].surveyItems.id].anwser_c) {
                        response += 'C';
                    }
                    if (this.response[this.items[this.itemView].surveyItems.id].anwser_d) {
                        response += 'D';
                    }
                    itemResult.results = response;
                } else {
                    // ITEM A RETOURNER AURA POUR TYPE MEDIA
                    itemResult.type = 'media';
                    // -----------------------------------
                }
                //ajouter l'id de la lesson a itemResult
                itemResult.lesson_id = this.$route.params.id;
                await ApiService.post(process.env.VUE_APP_API_URL + '/academy/lessonItemState', itemResult).then((result) => {
                    if (result.data) {
                        if (!result.data.error) {
                            if (this.items[this.itemView].survey_id) {
                                this.response.survey[this.items[this.itemView].survey_id].valid = result.data['lessonItemState']['sucess'];
                                this.response.survey[this.items[this.itemView].survey_id].result = result.data['lessonItemState']['score'];
                                if (result.data.end) {
                                    this.end = true;
                                }
                            } else if (this.items[this.itemView].survey_item_id) {
                                this.response[this.items[this.itemView].survey_item_id].valid = result.data['lessonItemState']['sucess'];
                            } else if (this.items[this.itemView].media_id) {
                                this.response.media[this.items[this.itemView].media_id].valid = result.data['lessonItemState']['sucess'];
                            }
                            this.items[this.itemView].userResult = result.data['lessonItemState'];
                            if (result.data['lessonItemState']['score'] >= 75 && result.data['lessonItemState']['score'] < 100) {
                                this.showWrongAnswers = true;
                            } else {
                                this.showQuestion = false;
                            }
                        }
                        window.document.getElementById('item').scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }
                });
                this.sendChrono();
            } else {
                this.classError = true;
            }
            this.check();
        },
        //
        // SI LE FORM EST FAUX ALORS ON SUPPRIME LES REPONSES DU FORM
        resetFail() {
            if (this.items[this.itemView].survey_id) {
                if (!this.response.survey[this.items[this.itemView].survey_id].valid) {
                    this.items[this.itemView].surveyItems.forEach((question) => {
                        this.response[question.id].anwser_a = false;
                        this.response[question.id].anwser_b = false;
                        this.response[question.id].anwser_c = false;
                        this.response[question.id].anwser_d = false;
                    });
                }
            } else if (this.items[this.itemView].survey_item_id) {
                if (!this.response[this.items[this.itemView].surveyItems.id].valid) {
                    this.response[this.items[this.itemView].surveyItems.id].anwser_a = false;
                    this.response[this.items[this.itemView].surveyItems.id].anwser_b = false;
                    this.response[this.items[this.itemView].surveyItems.id].anwser_c = false;
                    this.response[this.items[this.itemView].surveyItems.id].anwser_d = false;
                }
            }
            this.showQuestion = true;
        },
        // MET EN EVIDENCE LES BONNES REPONSES SI TEST REUSSI
        getClass(question, letter) {
            if (question.solution != question.answered && this.showWrongAnswers) {
                if (question.solution.toLowerCase().includes(letter)) {
                    return 'good-answer';
                } else if (!question.solution.toLowerCase().includes(letter) && !question.answered.toLowerCase().includes(letter)) {
                    return 'neutral-answer';
                } else {
                    return 'wrong-answer';
                }
            }
        },
        //END Navigation
        //////////////////////
        // BEGIN LESSON STATUS
        finish() {
            this.$router.push({
                name: 'classe',
                params: { id: this.classe_user.id }
            });
        },
        //END Navigation
        //////////////////////
        // BEGIN MENU ASIDE LEFT
        menuToogleBg(event) {
            if (getComputedStyle(event.currentTarget).backgroundColor != 'rgba(181, 181, 195, 0.17)') {
                event.currentTarget.style.backgroundColor = 'rgba(181, 181, 195, 0.17)';
            } else {
                event.currentTarget.style.backgroundColor = 'white';
            }
        },
        //END MENU ASIDE LEFT
        //////////////////////
        // BEGIN Mobile Check
        mobile() {
            if (window.screen.availWidth <= 992) {
                this.show = false;

                return true;
            } else {
                return false;
            }
        },
        // END Mobile Check
        // ////////////////////
        // BEGIN RELOADERS
        async reloadClasseUser() {
            await this.$store
                .dispatch(GET_CLASSEUSER, {
                    classeUser_id: this.$route.params.classeUserId
                })
                .then((result) => {
                    if (result.data) {
                        this.classe_user = result.data;
                    }
                });
        }
        // END Mobile Check
        // ////////////////////
    },
    watch: {
        showAlert() {
            //quand showAlert passe à true, si showAlert ne repasse pas à false dans les 30 secondes, on redirige vers classe user
            if (this.showAlert) {
                //wait 30 seconds then redirect to classe user
                setTimeout(() => {
                    if (this.showAlert) {
                        this.$router.push({
                            name: 'classe',
                            params: { id: this.classe_user.id, isPresent: false }
                        });
                    }
                }, parseInt(process.env.VUE_APP_ALERT_DURATION) * 1000);
            }
        },
        loading(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.clearLoadingTimeInterval();
                if (newValue) {
                    this.$_loadingTimeInterval = setInterval(() => {
                        this.loadingTime++;
                    }, 500);
                }
            }
        },
        loadingTime(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue === this.maxLoadingTime) {
                    this.loading = false;
                }
            }
        },
        userHaveClasse() {
            // L'utilisateur à la classe
            return true;
        },
        classError: function () {
            if (this.classError) {
                setTimeout(() => {
                    this.$router.push({ name: 'dashboard' });
                }, 5000);
            }
        }
    },
    async created() {
        this.$_loadingTimeInterval = null;
        if (this.$route.params.item) {
            this.itemView = this.$route.params.item;
        }
    }
};
</script>
<style>
.font-weight-bold {
    font-weight: 1000 !important;
}

.nl2br {
    max-height: 45%;
    overflow-y: auto;
    text-align: left;
    padding-right: 2px;
}

.nl2brLesson {
    max-height: 7em;
    overflow-y: auto;
    text-align: left;
    padding-right: 2px;
}

.scrollPanel::-webkit-scrollbar-track {
    background-color: #eee;
}

.scrollPanel::-webkit-scrollbar {
    width: 3px;
    background-color: #eee;
    z-index: 9999;
}

.scrollPanel::-webkit-scrollbar-thumb {
    background-color: #085a82;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.displayFullScreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 1em;
    z-index: 9999;
    overflow: hidden;
}

.wrong-answer-border {
    border: 2px solid red !important;
    border-radius: 3px;
    padding: 10px;
}

.wrong-answer {
    text-decoration: line-through;
    color: red;
}

.good-answer {
    color: green;
}

.neutral-answer {
    color: #3f4254;
}

@media screen and (max-width: 1267px) {
    .truncates {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media screen and (max-height: 685px) {
    .truncates {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media screen and (max-width: 900px) {
    .hide {
        position: absolute;
        z-index: -11110;
        display: none;
    }

    .width-media {
        width: 90% !important;
    }
}

.courseIcon img {
    width: 50%;
    fill: red !important;
}
.alertDuration {
    background-color: #212121;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    padding: 2rem;
    border-radius: 10px;
}

.alertDuration p,
.alertDuration h4 {
    color: white;
}

#item {
    position: relative;
}
</style>
